import { Route, Routes } from 'react-router-dom';
import React from 'react';

import AdminPage from '../../Admin/AdminPage';
import BuildingPage from '../../Building/BuildingPage';
import MainWrapper from '../Wrappers/MainWrapper';
import PageNotFound from '../../Errors/PageNotFound';
import RequireAuth from '../../../shared/auth/RequireAuth';
import ROLES from '../../../shared/auth/Roles';
import ROUTES from './Routes';

const Router = () => (
  <MainWrapper>
    <main>
      <div className="container__wrap">
        <Routes>
          <Route path={ROUTES.ADMIN} element={<RequireAuth allowdRoles={[ROLES.ADMINISTRATOR, ROLES.OPERATOR247]}><AdminPage /></RequireAuth>} />
          <Route path={ROUTES.BUILDING} element={<BuildingPage />} />
          <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
        </Routes>
      </div>
    </main>
  </MainWrapper>
);

export default Router;
