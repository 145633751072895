import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useNotification = () => {
  const { t } = useTranslation();
  const [conf, sendNotification] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleOpen = useCallback((key) => {
    if (conf.onSuccessRoute && conf.onSuccessRoute !== null) {
      navigate(conf.onSuccessRoute);
    }
    closeSnackbar(key);
  }, [closeSnackbar, conf.onSuccessRoute, navigate]);

  const content = useMemo((key) => (
    <Alert
      onClose={() => closeSnackbar(key)}
      key={key}
      variant="filled"
      severity={conf.variant}
      action={(
        <>
          {conf.onSuccessRoute && conf.variant === 'success' && (
            <IconButton
              size="small"
              aria-label="Open"
              title="Open"
              color="inherit"
              onClick={() => handleOpen(key)}
            >
              <FontAwesomeIcon icon={['fad', 'external-link']} />
            </IconButton>
          )}
          <IconButton
            size="small"
            aria-label="Close"
            title="Close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <FontAwesomeIcon icon={['fad', 'times-circle']} />
          </IconButton>
        </>
      )}
    >
      <AlertTitle>{conf.header}</AlertTitle>
      {conf.message}
    </Alert>
  ), [conf, closeSnackbar, handleOpen]);

  useEffect(() => {
    if (conf?.message) {
      let variant = 'info';
      if (conf.variant) {
        variant = conf.variant;
      }
      enqueueSnackbar(conf.message, {
        variant,
        content,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }, [conf, enqueueSnackbar, content]);

  const sendDefaultError = useCallback(() => {
    sendNotification({
      message: t('errors:somethingWentWrongPleaseTryAgainLater'),
      header: t('errors:oops'),
      variant: 'error',
    });
  }, [t]);
  return { sendNotification, sendDefaultError };
};

export default useNotification;
