const getBaseUri = () => {
  const getUrl = window.location;
  const baseUrl = `${getUrl.protocol}//${getUrl.host}/admin`;
  return baseUrl;
};

const { REACT_APP_KEYPOINT_IDP_URI } = process.env;

const customOidcConfig = (i18n) => ({
  clientId: 'kpcQRCode',
  automaticSilentRenew: true,
  redirectUri: getBaseUri(),
  responseType: 'code',
  scope: 'openid roles api profile',
  authority: REACT_APP_KEYPOINT_IDP_URI,
  revokeAccessTokenOnSignout: true,
  autoSignIn: false,
  onBeforeSignIn: () => {
    /**
      * This method gets executed before signin redirecting begins
      * it can be used to store Uri
      */
  },
  onSignIn: async (userData) => {
    const uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
      const cleanUri = uri.substring(0, uri.indexOf('?'));
      window.history.replaceState({}, document.title, cleanUri);

      i18n.changeLanguage(userData.profile.culture.toLowerCase());
    }
  },
});

export default customOidcConfig;
