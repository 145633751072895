import * as yup from 'yup';
import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { Button, ButtonToolbar, FormGroup } from 'reactstrap';
import {
  Form,
  Feedback as FormFeedback,
  Input,
} from '@availity/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getLanguageFromCode, isPhoneNumberValid } from '../../../shared/utils/helpers';
import InternationalPhoneNumber from '../../../shared/components/inputs/PhoneNumberInput';
import MultiFileUpload from '../../../shared/components/MultiFileUpload';
import RequiredLabel from '../../../shared/components/RequiredLabel';
import { useGetCaseClassifications } from '../../../queries/qr';

const CreateTicketForm = ({
  ticket, updateTicket, onSubmit, onCancel, allowEscalation,
}) => {
  const { t, i18n } = useTranslation(['common', 'ticket', 'errors']);
  const { language } = i18n;

  const { buildingTokenId } = useParams();

  const { caseClassifications, caseClassificationsAreLoading } = useGetCaseClassifications(buildingTokenId);

  const formattedClassifications = useMemo(() => {
    if (caseClassificationsAreLoading) return [];

    return caseClassifications.map((c) => {
      const { translations, id } = c;

      return ({ description: translations.find(translation => translation.language === getLanguageFromCode(language)).description, id })
    })
  }, [caseClassifications, caseClassificationsAreLoading, language]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        escalate: ticket.escalate,
        lastName: ticket.requester,
        phoneNumber: ticket.phoneNumber,
        problemDescription: ticket.problemDescription,
      }}
      validationSchema={yup.object().shape({
        email: yup.string().email(t('errors:invalidEmail')),
        lastName: yup.string().required(t('errors:fieldIsRequired')),
        phoneNumber: yup
          .object()
          .nullable()
          .test('valid PhoneNumber', t('errors:fieldIsInvalid'), () => {
            if (!ticket.phoneNumber?.number) return true;
            const valid = isPhoneNumberValid(ticket.phoneNumber.number, ticket.phoneNumber.countryCode, false, true);
            return valid;
          }),
        problemDescription: yup.string().required(t('errors:fieldIsRequired')),
      })}
      autoComplete="off"
    >
      <FormGroup>
        <Input
          label={t('ticket:firstName')}
          id="firstName"
          name="firstName"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, firstName: e.target.value })}
        />
        <FormFeedback name="firstName" />
      </FormGroup>

      <FormGroup>
        <Input
          label={<RequiredLabel translatedName={t('ticket:lastName')} />}
          id="lastName"
          name="lastName"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, lastName: e.target.value })}
        />
        <FormFeedback name="lastName" />
      </FormGroup>

      <FormGroup>
        <Input
          label={t('common:email')}
          id="email"
          name="email"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, email: e.target.value })}
        />
        <FormFeedback name="email" />
      </FormGroup>

      <FormGroup>
        <InternationalPhoneNumber
          label={t('common:phone')}
          callBack={(number, countryCode) => updateTicket({ ...ticket, phoneNumber: { countryCode, number } })}
        />
        <FormFeedback style={{ display: 'block' }} name="phoneNumber" />
      </FormGroup>

      <FormGroup>
        <Input
          label={t('ticket:apartmentNumber')}
          id="apartmentNumber"
          name="apartmentNumber"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, apartmentNumber: e.target.value })}
        />
        <FormFeedback name="apartmentNumber" />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          id="classification"
          name="classification"
          value={ticket.classification ?? ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => updateTicket({ ...ticket, classification: value })}
          renderInput={(params) => <TextField label={t('ticket:classification')} variant="standard" {...params} />}
          options={formattedClassifications}
          getOptionLabel={(option) => option.description ?? ('')}
          renderOption={(props, option) => (<li {...props} key={option.id}>{option.description}</li>)}
          autoSelect
        />
      </FormGroup>

      {allowEscalation && (
        <FormGroup style={{ textAlign: 'left' }}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={(
              <Input
                id="escalate"
                name="escalate"
                tag={Switch}
                variant="standard"
                type="checkbox"
                onChange={(e) => updateTicket({ ...ticket, escalate: e.target.checked })}
              />
            )}
            labelPlacement="start"
            label={t('ticket:urgent')}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Input
          label={<RequiredLabel translatedName={t('ticket:problemDescription')} />}
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          name="problemDescription"
          id="problemDescription"
          onChange={(e) => updateTicket({ ...ticket, problemDescription: e.target.value })}
        />
        <FormFeedback name="problemDescription" />
      </FormGroup>

      <FormGroup style={{ textAlign: 'left' }}>
        <MultiFileUpload files={ticket.images} setFiles={(images) => updateTicket({ ...ticket, images })} />
      </FormGroup>

      <ButtonToolbar className="modal__footer">
        <Button
          outline
          color="secondary"
          type="button"
          size="lg"
          onClick={onCancel}
        >
          <i><FontAwesomeIcon icon={['fad', 'times-circle']} /></i> {t('cancel')}
        </Button>
        <Button
          color="keypoint"
          type="submit"
          size="lg"
        >
          <i><FontAwesomeIcon icon={['fad', 'paper-plane']} /></i> {t('send')}
        </Button>
      </ButtonToolbar>
    </Form>
  );
};

CreateTicketForm.propTypes = {
  allowEscalation: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    classification: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
    }),
    escalate: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string.isRequired,
    })),
    phoneNumber: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
    }),
    problemDescription: PropTypes.string,
    requester: PropTypes.string,
  }),
  updateTicket: PropTypes.func.isRequired,
};

CreateTicketForm.defaultProps = {
  ticket: {
    apartmentNumber: '',
    classificationId: null,
    email: '',
    escalate: false,
    firstName: '',
    images: [],
    lastName: '',
    phoneNumber: {
      countryCode: '',
      number: '',
    },
    problemDescription: '',
  },
};

export default CreateTicketForm;
