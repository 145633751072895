import React, {
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import IconButton from '../buttons/IconButton';

const DatatableFilter = ({
  updateCustomFilter,
  children,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, updateActiveFilters] = useState({});
  const { t } = useTranslation(['common']);

  const updateActiveFilter = (filterProp, active) => {
    const copy = activeFilters;
    copy[filterProp] = active;
    updateActiveFilters({ ...copy });
  };

  const filterAddActiveCallback = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        activeCallback: updateActiveFilter,
      });
    }
    return child;
  });

  const hasFilters = useCallback(() => {
    if (!activeFilters) {
      return false;
    }
    return !Object.values(activeFilters).every((item) => item === false);
  }, [activeFilters]);

  const handleClearFilters = () => {
    updateCustomFilter({});
    updateActiveFilters({});
  };

  return (
    <>
      <IconButton
        iconStyle="fad"
        icon="filter-list"
        tooltip={t('filter')}
        onClick={() => setIsFilterOpen(true)}
        color={hasFilters() ? 'accent' : 'keypoint'}
        className="pt-3"
        size="2x"
        style={{
          position: 'absolute',
          top: -70,
          right: 0,
        }}
      />
      {hasFilters() && (
        <IconButton
          iconStyle="fad"
          icon="circle-xmark"
          color="keypoint"
          tooltip={t('clearFilter')}
          onClick={handleClearFilters}
          style={{
            position: 'absolute',
            top: -60,
            right: -10,
          }}
        />
      )}
      {isFilterOpen && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={() => setIsFilterOpen(false)}
        />
      )}
      <Collapse
        className="datatable-filter"
        isOpen={isFilterOpen}
        style={{
          position: 'relative',
        }}
      >
        <h4 className="title">Filter</h4>
        <hr />
        { filterAddActiveCallback }
      </Collapse>
    </>
  );
};

DatatableFilter.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  updateCustomFilter: PropTypes.func,
};

DatatableFilter.defaultProps = {
  updateCustomFilter: () => {},
};

export default DatatableFilter;
