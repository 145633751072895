import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DOMPurify from 'dompurify';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BuildingTeamInfo from './Components/BuildingTeamInfo';
import CreateTableData from '../../shared/utils/CreateTableData';
import CreateTicketModal from './Ticket/CreateTicketModal';
import DataReactTable from '../../shared/components/table/components/DataReactTable';
import IconButton from '../../shared/components/buttons/IconButton';
import LoadingSpinner from '../Layout/LoadingSpinner';
import MessageOfTheDayDisplay from './Components/MessageOfTheDayDisplay';
import ROUTES from '../App/Router/Routes';
import TicketButton from './Components/TicketButton';
import TicketInfoModal from './Ticket/TicketInfoModal';
import { useGetPortalData } from '../../queries/qr';
import useToggle from '../../shared/hooks/UseToggle';

const BuildingPage = () => {
  const { t, i18n } = useTranslation(['common', 'building']);
  const { language } = i18n;
  const navigate = useNavigate();

  const { buildingTokenId } = useParams();

  const { portalData, portalDataIsLoading } = useGetPortalData(buildingTokenId);

  const createTicketToggle = useToggle();

  const [ticketDataTableData, setTicketDataTableData] = useState({});

  const [ticketInfoModalId, setTicketInfoModalId] = useState();
  const ticketInfoModalToggle = useToggle();

  useEffect(() => {
    if (!portalData && !portalDataIsLoading) {
      navigate(ROUTES.PAGE_NOT_FOUND);
    }
  }, [navigate, portalData, portalDataIsLoading]);

  const settings = useMemo(() => {
    if (portalDataIsLoading || !portalData) return null;

    const { portalSettings, teamQrSettings } = portalData;

    return {
      ...teamQrSettings,
      ...portalSettings
    };
  }, [portalData, portalDataIsLoading]);

  function setCorrectTicketProperties(data) {
    const results = [];
    if (!data || data.length <= 0) {
      return results;
    }

    data.forEach((ticket) => {
      const result = { ...ticket };
      Object.keys(result).forEach((key) => {
        if (!result[key]) {
          result[key] = '-';
        }
      });
      results.push(result);
    });
    return results;
  }

  const showTicketInfoModal = (id) => {
    setTicketInfoModalId(id);
    ticketInfoModalToggle.show();
  };

  const ticketInfoDisplay = useCallback((info, id) => {
    const infoDisplayed = `${info.substring(0, info.length < 200 ? info.length : 200)}${info.length > 200 ? '...' : ''}`;

    return (
      <Container>
        {infoDisplayed}
        <IconButton icon="info-circle" onClick={() => showTicketInfoModal(id)} />
      </Container>
    );
  }, []);

  useEffect(() => {
    if (!portalData) return;
    if (portalData.cases.length === 0) {
      setTicketDataTableData({});
    } else {
      const buttons = [];
      buttons.push({
        accessor: 'description',
        content: ((col) => ticketInfoDisplay(col.value, col.row.original.id)),
      });

      const properties = ['description'];
      const data = CreateTableData({
        buttons,
        jsonData: setCorrectTicketProperties(portalData.cases),
        properties,
        t,
      });
      setTicketDataTableData(data);
    }
  }, [portalData, t, ticketInfoDisplay]);

  return (
    <Container>
      {(!portalDataIsLoading && portalData) ? (
        <>
          {portalData.whiteLabelUrl && (
            <img src={portalData.whiteLabelUrl} alt="Building logo" className="mb-3" />
          )}
          {settings.showContactInformation && (
            <BuildingTeamInfo name={portalData.teamName} emailAddress={settings.generalEmailAddress} phoneNumber={settings.generalPhoneNumber} />
          )}

          <Stack>
            {portalData.portalSettings?.messagesOfTheDay.map((m) =>
              <MessageOfTheDayDisplay key={m.id} message={m} backgroundHex='#0047a0' language={language} />)}

            <Card className="mb-3 mt-3">
              <CardHeader style={{ textAlign: 'center' }} tag="h2">
                {portalData.name}
              </CardHeader>
              <CardBody>
                {portalData.administrators && (
                  <Row>
                    <Col xs={4}>{t('building:administrator')}:</Col>
                    <Col xs={8}>{portalData.administrators.join(', ')}</Col>
                  </Row>
                )}
                {portalData.address && (
                  <Row>
                    <Col xs={4}>{t('building:address')}:</Col>
                    <Col xs={8}>
                      {portalData.address.addressLine
                        ? `${portalData.address.addressLine}, `
                        : null}
                      {portalData.address.postalCode
                        ? `${portalData.address.postalCode} `
                        : null}
                      {portalData.address.city
                        ? `${portalData.address.city} `
                        : null}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>

            {portalData.info && (
              <Card className="mb-3">
                <CardBody
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(portalData.info),
                  }}
                />
              </Card>
            )}

            {portalData.cases.length > 0 && (
              <DataReactTable
                title={t('common:tickets')}
                reactTableData={ticketDataTableData}
              />
            )}
          </Stack>

          <TicketButton toggle={createTicketToggle} />
          {createTicketToggle.value && (
            <CreateTicketModal isOpen={createTicketToggle.value} setIsOpen={createTicketToggle.setValue} buildingTokenId={buildingTokenId} allowEscalation={settings.allowEscalation} />
          )}

          {ticketInfoModalId && (
            <TicketInfoModal
              setIsOpen={ticketInfoModalToggle.hide}
              isOpen={ticketInfoModalToggle.value}
              ticket={portalData.cases.find((ti) => ti.id === ticketInfoModalId)}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default BuildingPage;
