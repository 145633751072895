import { Alert } from '@mui/material';
import DOMPurify from 'dompurify';
import { useMemo } from 'react';

import Color from '../../../shared/utils/Color';
import { getLanguageFromCode } from '../../../shared/utils/helpers';
import { MessageOfTheDay } from '../../../shared/types/qr/MessageOfThDay';

type Props = {
  message: MessageOfTheDay;
  backgroundHex: string;
  language: string;
};

const MessageOfTheDayDisplay: React.FC<Props> = ({ message, backgroundHex, language }) => {
  const { textColor } = new Color(backgroundHex.replace('#', ''));

  const messageDisplay = useMemo(
    () =>
      message.translations.find((tr) => tr.language === getLanguageFromCode(language))!.description,
    [language, message.translations],
  );

  return (
    <Alert
      className="building-motd"
      style={{ backgroundColor: backgroundHex, color: `#${textColor}`, fill: `#${textColor}` }}
      severity="info"
    >
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messageDisplay) }} />
    </Alert>
  );
};

export default MessageOfTheDayDisplay;
