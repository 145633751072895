import axios from "axios";

const { REACT_APP_KEYPOINT_API_URI } = process.env;

const createRestClient = () => {
  const restClient = axios.create({ baseURL: REACT_APP_KEYPOINT_API_URI });

  restClient.interceptors.request.use({
    // @ts-ignore
    onRejected: (error) => Promise.reject(error),
  });

  restClient.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );

  return restClient;
};

export default createRestClient;
