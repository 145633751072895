import Language from '../enums/language';
import LanguageCode from '../enums/languageCode';

export const hasEqualType = (item1, item2) => {
  if (typeof item1 !== typeof item2) {
    return false;
  }
  return true;
};

export const isEmptyObject = (obj) => Object.getOwnPropertyNames(obj).length === 0;

export const isIterable = (obj) => {
  if (!obj) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

export const getEqualPropertiesInObjects = (obj1, obj2) => {
  if (!obj1 || !obj2) {
    return null;
  }

  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    return null;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  const sameValues = {};

  obj1Keys.forEach((key) => {
    if (!obj2Keys.includes(key)) {
      return;
    }

    const item1 = obj1[key];
    const item2 = obj2[key];

    if (!hasEqualType(item1, item2)) {
      return;
    }

    if (item1 instanceof Object && item2 instanceof Object) {
      sameValues[key] = getEqualPropertiesInObjects(item1, item2);
    } else if (item1 === item2) {
      sameValues[key] = item1;
    }
  });

  return sameValues;
};

export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      flattened[key] = obj[key].join(', ');
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const getIssueFilterIconDetails = (key) => {
  switch (key) {
    case 'showLogs':
      return {
        color: '',
        icon: 'history',
      };
    case 'showStatuses':
      return {
        color: '#04CBC0',
        icon: 'heart-rate',
      };
    case 'showNotes':
      return {
        color: '#E3B622',
        icon: 'sticky-note',
      };
    case 'showPhoneCalls':
      return {
        color: '#11ba55',
        icon: 'phone-volume',
      };
    case 'showDocuments':
      return {
        color: '#B922E3',
        icon: 'file',
      };
    case 'showSms':
      return {
        color: '#3402C9',
        icon: 'sms',
      };
    case 'showEmails':
      return {
        color: '#0267E3',
        icon: 'at',
      };
    default:
      throw new Error(`Unsupported key '${key}'`);
  }
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmailAddress = (emailAddress) =>
  String(emailAddress).toLowerCase().match(emailRegex);

export const textColors = {
  BLACK: '646777',
  WHITE: 'ffffff',
};

// takes in hex string and converts to decimal number
function hexToDecimal(hexString) {
  return parseInt(hexString, 16);
}

/* converts a hex string to an object with 'r', 'g', 'b'
  as the keys and their respective values */
function hexToRGB(hex) {
  const r = hexToDecimal(hex.substring(0, 2));
  const g = hexToDecimal(hex.substring(2, 4));
  const b = hexToDecimal(hex.substring(4, 6));

  return { r, g, b };
}

// calculates relative luminance given a hex string
export function getLuminance(hex) {
  const rgb = hexToRGB(hex);

  Object.keys(rgb).forEach((key) => {
    let c = rgb[key];
    c /= 255;

    c = c > 0.03928 ? ((c + 0.055) / 1.055) ** 2.4 : (c /= 12.92);

    rgb[key] = c;
  });

  return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
}

// calculates contrast ratio between two hex strings
export function contrastRatioPair(hex1, hex2) {
  const lum1 = getLuminance(hex1);
  const lum2 = getLuminance(hex2);

  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}

export const getLanguageFromCode = (code) => {
  switch (code) {
    case LanguageCode.en:
      return Language.English;
    case LanguageCode.nl:
      return Language.Dutch;
    case LanguageCode.fr:
      return Language.French;
    default:
      return Language.English;
  }
};

export const formatPhoneNumber = (item) => `${`+${item.countryCode}`}${item.number}`;

export const isPhoneNumberValid = (phoneNumber, countryCode, isMobile, allowBoth) => {
  const countryLengths = {
    // Add more country codes and lengths as needed
    30: { landline: 10, mobile: 10 }, // Greece
    31: { landline: 10, mobile: 9 }, // Netherlands
    32: { landline: 8, mobile: 9 }, // Belgium
    33: { landline: 10, mobile: 9 }, // France
    34: { landline: 9, mobile: 9 }, // Spain
    351: { landline: 9, mobile: 9 }, // Portugal
    352: { landline: 7, mobile: 8 }, // Luxembourg
    353: { landline: 8, mobile: 9 }, // Ireland
    354: { landline: 7, mobile: 7 }, // Iceland
    356: { landline: 8, mobile: 8 }, // Malta
    358: { landline: 9, mobile: 10 }, // Finland
    359: { landline: 8, mobile: 9 }, // Bulgaria
    36: { landline: 8, mobile: 9 }, // Hungary
    370: { landline: 8, mobile: 8 }, // Lithuania
    371: { landline: 8, mobile: 8 }, // Latvia
    372: { landline: 7, mobile: 8 }, // Estonia
    380: { landline: 9, mobile: 9 }, // Ukraine
    381: { landline: 8, mobile: 8 }, // Serbia
    385: { landline: 8, mobile: 9 }, // Croatia
    386: { landline: 8, mobile: 8 }, // Slovenia
    389: { landline: 8, mobile: 8 }, // Macedonia
    39: { landline: 9, mobile: 10 }, // Italy
    40: { landline: 9, mobile: 9 }, // Romania
    41: { landline: 8, mobile: 9 }, // Switzerland
    420: { landline: 9, mobile: 9 }, // Czech Republic
    421: { landline: 8, mobile: 9 }, // Slovakia
    43: { landline: 12, mobile: 13 }, // Austria
    44: { landline: 10, mobile: 10 }, // UK
    45: { landline: 8, mobile: 8 }, // Denmark
    46: { landline: 8, mobile: 9 }, // Sweden
    47: { landline: 8, mobile: 8 }, // Norway
    48: { landline: 9, mobile: 9 }, // Poland
    49: { landline: 10, mobile: 11 }, // Germany
    90: { landline: 10, mobile: 11 }, // Turkey
  };

  const actualLength = phoneNumber.replace(/\D/g, '').length; // Remove all non-digits from phone number
  const expectedLengths = countryLengths[countryCode];

  if (allowBoth && !!expectedLengths) {
    return actualLength === expectedLengths.mobile || actualLength === expectedLengths.landline;
  }

  let expectedLength = isMobile ? expectedLengths?.mobile : expectedLengths?.landline;
  if (!expectedLength) {
    expectedLength = 9; // 9 as fallback
  }

  return actualLength === expectedLength;
};
