import {
  Col,
  Modal,
  Row,
} from 'reactstrap';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TicketInfoModal = ({
  isOpen, setIsOpen, ticket,
}) => {
  const { t } = useTranslation(['ticket']);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      className="modal-dialog--primary"
    >
      <div className="modal__header">
        <button
          type="button"
          aria-label="modal__close-btn"
          className="lnr lnr-cross modal__close-btn"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-modal modal__title">{t('ticketDetails')}</h5>
      </div>
      <div className="modal__body" style={{ textAlign: 'start' }}>
        <Row>
          <Col md={6}>
            <Row>
              <Col xs={4}>{t('notificationDate')}:</Col>
              <Col>{dayjs(ticket.created).format("DD/MM/YYYY")}</Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={4}>{t('state')}:</Col>
              <Col>{ticket.status}</Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={4}>{t('administrator')}:</Col>
              <Col>{ticket.admins.join(', ')}</Col>
            </Row>
          </Col>
          <Col xs={12}>{t('info')}:</Col>
          <Col xs={12}>{ticket.description}</Col>
        </Row>
      </div>
    </Modal>
  );
};

TicketInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    admins: PropTypes.arrayOf(PropTypes.string),
    created: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default TicketInfoModal;
