import React, { useState, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../containers/Layout/LoadingSpinner';
import ROLES from './Roles';
import API from '../api/ApiService';

const RequireAuth = ({ children, allowdRoles }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const { isLoading: isUserLoading, userData, signIn } = useAuth();

  useEffect(() => {
    if (!isLoading && !isLoadingUser) {
      setIsLoaded(true);
    }
  }, [isLoadingUser, isLoading]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        setIsLoadingUser(isUserLoading);
        if (!isUserLoading && userData) {
          setIsAuthenticated(true);
          API.setToken(userData.access_token);
        }
      } catch (err) {
        setIsAuthenticated(false);
      }
    };
    loadUser();
  }, [isUserLoading, userData]);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const response = () => {
    if (isAuthenticated) {
      if (!allowdRoles.length || (allowdRoles.length && allowdRoles.includes(userData.profile.role))) {
        return children;
      }
      return <span>Unautherized</span>;
    }

    if (isLoaded) {
      return signIn();
    }
    return <LoadingSpinner />;
  };

  return response();
};

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired,
  allowdRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ROLES))),
};

RequireAuth.defaultProps = {
  allowdRoles: [],
};

export default RequireAuth;
