import { useQuery } from '@tanstack/react-query';

import qrApi from '../../shared/api/qr/qr';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetCaseClassifications = (qrTokenId: string) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    onError: sendDefaultError,
    queryFn: () => qrApi.getCaseClassifications(qrTokenId),
    queryKey: [queryIds.qr.GET_CASE_CLASSIFICATIONS, qrTokenId],
  });

  return {
    caseClassifications: data ?? [],
    caseClassificationsAreLoading: isLoading,
  };
};

export default useGetCaseClassifications;
