import { CaseClassification } from '../../types/case/CaseClassification';
import createRestClient from '../restClient';
import { PortalData } from '../../types/qr/PortalData';

const client = createRestClient();

const getBuildingInfo = (qrTokenId: string): Promise<PortalData> => client.get(`qr/${qrTokenId}`);

const getCaseClassifications = (qrTokenId: string): Promise<CaseClassification[]> =>
  client.get(`qr/${qrTokenId}/case-classifications`);

const createCase = (data: FormData) => client.post('qr/case', data);

export default {
  createCase,
  getBuildingInfo,
  getCaseClassifications,
};
