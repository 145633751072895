import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import ReactTableBase from '../ReactTableBase';
import ReactTableCustomizer from './ReactTableCustomizer';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({
  reactTableData, title, subtitle, showCustomizer, editable, sortable, customHeaderButton, customFilter, sortBy,
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [isEditable, setIsEditable] = useState(editable);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(sortable);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    setData(reactTableData.tableRowsData);
  }, [reactTableData.tableRowsData]);

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData((old) => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    customHeaderButton,
    customFilter,
    manualPageSize: [10, 20, 30, 40],
    placeholder: t('search'),
    sortBy,
  };

  return (
    <Col md={12} lg={12} className="react-table-col-wrapper">
      <Card>
        <CardBody className="react-table-card-body">
          <div className="react-table__wrapper">
            {(title || subtitle) && (
              <div className="card__title">
                {title && (<h5 className="bold-text">{title}</h5>)}
                {subtitle && (<h5 className="subhead">{subtitle}</h5>)}
              </div>
            )}
            {showCustomizer ? (
              <ReactTableCustomizer
                handleClickIsEditable={handleClickIsEditable}
                handleClickIsResizable={handleClickIsResizable}
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithDragAndDrop={handleClickWithDragAndDrop}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                isEditable={isEditable}
                isResizable={isResizable}
                isSortable={isSortable}
                isDisabledDragAndDrop={isDisabledDragAndDrop}
                isDisabledEditable={isDisabledEditable}
                isDisabledResizable={isDisabledResizable}
                withDragAndDrop={withDragAndDrop}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
                fullCustomizer
              />
            ) : null}
          </div>
          <ReactTableBase
            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={rows}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showCustomizer: PropTypes.bool,
  editable: PropTypes.bool,
  sortable: PropTypes.bool,
  customHeaderButton: PropTypes.element,
  customFilter: PropTypes.element,
  sortBy: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
  })),
};

DataReactTable.defaultProps = {
  title: null,
  subtitle: null,
  showCustomizer: false,
  editable: false,
  sortable: true,
  customHeaderButton: null,
  customFilter: null,
  sortBy: [],
};

export default DataReactTable;
