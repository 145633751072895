import PropTypes from 'prop-types';

const {
  string, shape, arrayOf, func,
} = PropTypes;

export const CreateTableButtonProps = shape({
  accessor: string,
  content: func.isRequired,
});

export const CreateTableButtonsProps = arrayOf(CreateTableButtonProps);
