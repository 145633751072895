import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TextField,
  Autocomplete,
  Stack,
} from '@mui/material';
import {
  FormGroup,
  Card,
  CardBody,
  Container,
  Button,
  Col,
  Row,
} from 'reactstrap';
import API from '../../shared/api/ApiService';
import DataReactTable from '../../shared/components/table/components/DataReactTable';
import CreateTableData from '../../shared/utils/CreateTableData';
import CreateTicketModal from './Ticket/CreateTicketModal';
import AddNoteModal from './Ticket/AddNoteModal';
import TopBar from '../Layout/topbar/Topbar';
import IconButton from '../../shared/components/buttons/IconButton';

const AdminPage = () => {
  const [actors, setActors] = useState([]);
  const [selectedActor, setSelectedActor] = useState('');
  const [actorBuildings, setActorBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState();
  const [buildingTickets, setBuildingTickets] = useState([]);
  const [buildingSuppliers, setBuildingSuppliers] = useState([]);

  const [ticketDataTableData, setTicketDataTableData] = useState({});
  const [supplierDataTableData, setSupplierDataTableData] = useState({});

  const [isAddNewTicketOpen, setIsAddNewTicketOpen] = useState(false);
  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
  const [ticketToAddNoteTo, setTicketToAddNoteTo] = useState();

  const { t } = useTranslation(['common', 'admin', 'ticket', 'building']);

  useEffect(() => {
    API.fetchGet247Actors()
      .then((response) => {
        response.json()
          .then((data) => setActors(data));
      });
  }, []);

  useEffect(() => {
    setActorBuildings([]);
    setSelectedBuilding('');

    if (selectedActor) {
      API.fetchGetBuildingsOwnedActor(selectedActor.id)
        .then((response) => {
          response.json()
            .then((data) => setActorBuildings(data));
        });
    }
  }, [selectedActor]);

  useEffect(() => {
    setBuildingTickets([]);
    setBuildingSuppliers([]);

    if (selectedBuilding) {
      API.fetchGetBuildingTickets(selectedBuilding.id)
        .then((response) => {
          response.json()
            .then((data) => {
              setBuildingTickets(data);
            });
        });

      API.fetchGetBuildingSuppliers(selectedBuilding.id)
        .then((response) => {
          response.json()
            .then((data) => {
              setBuildingSuppliers(data);
            });
        });
    }
  }, [selectedBuilding]);

  function setCorrectTickitProperties(data) {
    const results = [];
    if (data === null || data === undefined || data.length <= 0) {
      return results;
    }

    data.forEach((ticket) => {
      const result = {
        ...ticket,
        notificationDate: new Date(ticket.notificationDate),
      };
      Object.keys(result).forEach((key) => {
        if (result[key] === null) {
          result[key] = '-';
        }
      });
      results.push(result);
    });
    return results;
  }

  const getCorrectDateDisplay = useCallback((date) => (
    date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })
  ), []);

  const openAddTicketModal = (ticketId) => {
    setIsAddNoteOpen(true);
    setTicketToAddNoteTo(ticketId);
  };

  const getAddNoteBtn = useCallback((id) => (
    <IconButton iconStyle="fal" size="2x" icon="comment-alt-plus" tooltip={t('ticket:newNote')} onClick={() => openAddTicketModal(id)} />
  ), [t]);

  useEffect(() => {
    if (buildingTickets.length === 0) {
      setTicketDataTableData({});
    } else {
      const buttons = [];
      buttons.push({
        accessor: 'notificationDate',
        content: ((col) => getCorrectDateDisplay(col.value)),
      });
      buttons.push({
        accessor: 'id',
        content: ((col) => getAddNoteBtn(col.value)),
      });

      const properties = ['reference', 'requestorName', 'info', 'notificationDate', 'id'];
      const data = CreateTableData({
        jsonData: setCorrectTickitProperties(buildingTickets),
        properties,
        disableGlobalFilterProperties: ['notificationDate'],
        buttons,
        t,
      });
      setTicketDataTableData(data);
    }
  }, [buildingTickets, getAddNoteBtn, getCorrectDateDisplay, t]);

  function setCorrectSupplierData(data) {
    const results = [];
    if (data === null || data === undefined || data.length <= 0) {
      return results;
    }

    data.forEach((supplier) => {
      const result = {
        ...supplier,
        email: supplier.email.join(', '),
      };
      Object.keys(result).forEach((key) => {
        if (result[key] === null) {
          result[key] = '-';
        }
      });
      results.push(result);
    });
    return results;
  }

  useEffect(() => {
    if (buildingSuppliers.length === 0) {
      setSupplierDataTableData({});
    } else {
      const properties = ['displayName', 'phone', 'mobilePhone', 'email', 'info'];
      const data = CreateTableData({
        jsonData: setCorrectSupplierData(buildingSuppliers),
        properties,
        t,
      });
      setSupplierDataTableData(data);
    }
  }, [buildingSuppliers, t]);

  return (
    <>
      <TopBar />
      <Container>
        <Card>
          <CardBody>
            <FormGroup>
              <Autocomplete
                id="syndicate"
                name="syndicate"
                value={selectedActor}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, actor) => setSelectedActor(actor)}
                renderInput={(params) => <TextField label={t('admin:buildingManager')} variant="standard" {...params} />}
                options={actors.map((a) => ({ id: a.id, name: a.companyName }))}
                getOptionLabel={(option) => option.name ?? ('')}
                renderOption={(props, option) => (<li {...props} key={option.id}>{option.name}</li>)}
                autoSelect
              />
            </FormGroup>

            {selectedActor && (
            <FormGroup>
              <Autocomplete
                id="Building"
                name="Building"
                openOnFocus
                value={selectedBuilding}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, building) => setSelectedBuilding(building)}
                renderInput={(params) => <TextField label={t('admin:building')} variant="standard" {...params} />}
                options={actorBuildings}
                getOptionLabel={(option) => option.name ?? ('')}
                renderOption={(props, option) => (<li {...props} key={option.id}>{option.name}</li>)}
              />
            </FormGroup>
            )}

            {selectedBuilding && (
              <Stack spacing={2}>
                <Card>
                  <CardBody>
                    <Row>
                      {selectedBuilding.administrator && (
                      <Col md={6}>{t('building:administrator')}: {selectedBuilding.administrator}</Col>
                      )}
                      {selectedBuilding.address && (
                      <Col md={6}>
                        {t('building:address')}: {selectedBuilding.address.street ? `${selectedBuilding.address.street}, ` : null}
                          {selectedBuilding.address.postalCode ? `${selectedBuilding.address.postalCode} ` : null}
                          {selectedBuilding.address.city ? `${selectedBuilding.address.city} ` : null}
                      </Col>
                      )}
                      {selectedBuilding.escalationPhoneNumbers && (
                      <Col md={6}>
                        {t('building:escalationNumbers')}:
                        <ul className="list-unstyled escalation-phone-numbers pl-1">
                          {selectedBuilding.escalationPhoneNumbers.map((number) => <li key={number}><i><FontAwesomeIcon icon={['fad', 'phone']} /></i> <a href={`tel:${number}`}>{number}</a></li>)}
                        </ul>
                      </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>

                <DataReactTable
                  title={t('admin:suppliers')}
                  reactTableData={supplierDataTableData}
                />
                <DataReactTable
                  title={t('common:tickets')}
                  reactTableData={ticketDataTableData}
                  customHeaderButton={(
                    <Button
                      type="button"
                      className="mb-0"
                      color="keypoint"
                      size="sm"
                      onClick={() => setIsAddNewTicketOpen(true)}
                    >
                      <i><FontAwesomeIcon icon={['fad', 'plus']} /></i> {t('ticket:createNewTicket')}
                    </Button>
                  )}
                  sortBy={[{ id: 'notificationDate', desc: true }]}
                />
              </Stack>
            )}
          </CardBody>
        </Card>
      </Container>
      {(selectedBuilding && selectedActor) && (
        <CreateTicketModal isOpen={isAddNewTicketOpen} setIsOpen={setIsAddNewTicketOpen} buildingId={selectedBuilding.id} actorId={selectedActor.id} setTickets={setBuildingTickets} />
      )}
      {ticketToAddNoteTo && (
        <AddNoteModal setIsOpen={setIsAddNoteOpen} isOpen={isAddNoteOpen} ticketId={ticketToAddNoteTo} />
      )}
    </>
  );
};

export default AdminPage;
