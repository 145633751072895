import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { TextField } from '@mui/material';
import DatatableFilter from '../../filter/DatatableFilter';

const ReactTableFilter = ({
  rows,
  customFilterValues, updateCustomFilter,
  setGlobalFilter, setFilterValue,
  globalFilter, placeholder, dataLength,
  customFilter,
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((item) => {
    setGlobalFilter(item || '');
  }, 200);

  useEffect(() => {
    if (value) {
      setFilterValue(value);
    }
  }, [value, setFilterValue]);

  const customFilterUpdate = (newData) => {
    updateCustomFilter(newData);
    setGlobalFilter(value || '');
  };

  const customFiltersWithProps = React.Children.map(customFilter, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        rows,
        updateCustomFilter: customFilterUpdate,
        customFilterValues,
      });
    }
    return child;
  });

  return (
    <div>
      <TextField
        className="table__search table__search-input"
        variant="standard"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        label={`${placeholder}`}
      />
      {customFilter && (
        <DatatableFilter>
          {customFiltersWithProps}
        </DatatableFilter>
      )}
      {dataLength !== rows.length && (
        <span>Found {rows.length} matches</span>
      )}
    </div>
  );
};

const filterGreaterThan = (rows, id, filterValue) => rows.filter((row) => {
  const rowValue = row.values[id];
  return rowValue >= filterValue;
});

filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

ReactTableFilter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  updateCustomFilter: PropTypes.func,
  globalFilter: PropTypes.string,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  customFilter: PropTypes.element,
  customFilterValues: PropTypes.shape(),
};

ReactTableFilter.defaultProps = {
  setFilterValue: () => {},
  updateCustomFilter: () => {},
  globalFilter: undefined,
  placeholder: 'Search...',
  customFilter: null,
  customFilterValues: {},
};

export default ReactTableFilter;
