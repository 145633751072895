import { Fab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Color from '../../../shared/utils/Color';
import { Toggle } from '../../../shared/types';

type Props = {
  toggle: Toggle;
  backgroundHex?: string;
};

const TicketButton: React.FC<Props> = ({ toggle, backgroundHex = '#0047a0' }) => {
  const { textColor } = new Color(backgroundHex.replace('#', ''));

  return (
    <Fab
      onClick={toggle.show}
      className="ticketbutton"
      size="medium"
      style={{ backgroundColor: backgroundHex, color: `#${textColor}` }}
    >
      <FontAwesomeIcon icon="plus" />
    </Fab>
  );
};

export default TicketButton;
