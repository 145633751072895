import { useQuery } from '@tanstack/react-query';

import { Nullable } from '../../shared/types';
import { PortalData } from '../../shared/types/qr/PortalData';
import qrApi from '../../shared/api/qr/qr';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetPortalData = (qrTokenId: string) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery<Nullable<PortalData>, boolean>({
    onError: sendDefaultError,
    queryFn: () => qrApi.getBuildingInfo(qrTokenId),
    queryKey: [queryIds.qr.GET_BUILDING_INFO, qrTokenId],
  });

  return {
    portalData: data,
    portalDataIsLoading: isLoading,
  };
};

export default useGetPortalData;
