import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * IconButton to display icons with an onClick event.
 * @component
 * @example ../../../../styleguide/Buttons/IconButton.md
 */
const IconButton = ({
  icon,
  iconStyle,
  onClick,
  tooltip,
  color,
  size,
  className,
}) => {
  const fullClass = `icon__only ${className}`;
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  return (
    <span>
      <button
        id="addNoteBtn"
        type="button"
        onClick={onClick}
        className={fullClass}
      >
        <FontAwesomeIcon size={size} icon={[iconStyle, icon]} color={color} />
      </button>
      <Tooltip target="addNoteBtn" placement="left" isOpen={tooltipIsOpen} toggle={() => setTooltipIsOpen(!tooltipIsOpen)} autohide={false}>
        {tooltip}
      </Tooltip>
    </span>
  );
};

IconButton.propTypes = {
  /** The icon to show */
  icon: PropTypes.string.isRequired,
  /** The icon style to use */
  iconStyle: PropTypes.oneOf(['far', 'fal', 'fad', 'fas', 'fa']),
  /** onClick action */
  onClick: PropTypes.func.isRequired,
  /** Tooltip */
  tooltip: PropTypes.string,
  /** Icon color */
  color: PropTypes.string,
  /** Icon size */
  size: PropTypes.string,
  /** Additional button classes */
  className: PropTypes.string,
};

IconButton.defaultProps = {
  tooltip: null,
  color: 'primary',
  iconStyle: 'fad',
  size: '1x',
  className: '',
};

export default IconButton;
