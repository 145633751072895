import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useEffect, useState } from 'react';

import 'react-phone-input-2/lib/plain.css';
import { Nullable } from '../../types';

const DEFAULT_COUNTRY_BE = '32';

type Props = {
  callBack: (number: string, dialCode: string) => void;
  value?: Nullable<string>;
};

const InternationalPhoneNumber: React.FC<Props> = ({ callBack, value, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (number: string, data: CountryData) => {
    setInputValue(number);
    callBack(number.substring(data.dialCode.length), data.dialCode);
  };

  useEffect(() => {
    if (value === null) {
      return;
    }

    if (value) {
      setInputValue(value);
    } else {
      setInputValue(DEFAULT_COUNTRY_BE);
    }
  }, [value]);

  return (
    <PhoneInput
      country="be"
      preferredCountries={['be']}
      regions="europe"
      value={inputValue}
      countryCodeEditable
      enableSearch
      onChange={handleOnChange}
      inputClass="w-100 align-self-end"
      disableDropdown
      {...rest}
    />
  );
};

export default InternationalPhoneNumber;
