import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Nullable, PhoneNumber } from '../../../shared/types';

import Color from '../../../shared/utils/Color';
import { formatPhoneNumber } from '../../../shared/utils/helpers';

type Props = {
  phoneNumber: Nullable<PhoneNumber>;
  backgroundHex?: string;
};

const PhoneCallButton: React.FC<Props> = ({ phoneNumber, backgroundHex = '#0047a0' }) => {
  const { textColor } = new Color(backgroundHex.replace('#', ''));

  return (
    <a
      className="phoneCallButton"
      href={`tel:${formatPhoneNumber(phoneNumber)}`}
      style={{ backgroundColor: backgroundHex, color: `#${textColor}` }}
    >
      <FontAwesomeIcon icon="phone" />
    </a>
  );
};

export default PhoneCallButton;
