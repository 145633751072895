import React from 'react';
import PropTypes from 'prop-types';

const RequiredLabel = ({ translatedName }) => (
  <span>{translatedName}<sup className="text-danger"> *</sup></span>
);

RequiredLabel.propTypes = {
  translatedName: PropTypes.string.isRequired,
};

export default RequiredLabel;
