import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopBar = () => {
  const { signOutRedirect } = useAuth();
  const { t } = useTranslation('common');

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left" />
        <div className="topbar__right">
          <button type="button" className="topbar__button" onClick={signOutRedirect}>
            <i><FontAwesomeIcon className="topbar__button-icon" icon={['fad', 'sign-out']} /></i> {t('logOut')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
