import Backdrop from '@mui/material/Backdrop';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateTicketForm from './CreateTicketForm';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import useCreateCase from '../../../mutations/qr/useCreateCase';

const CreateTicketModal = ({
  isOpen,
  setIsOpen,
  buildingTokenId,
  allowEscalation,
}) => {
  const { t } = useTranslation(['ticket', 'common']);

  const { createCase, isCreatingCase } = useCreateCase();

  const [ticket, setTicket] = useState();

  const submit = () => {
    if (isCreatingCase) return;

    const ticketFormData = new FormData();
    ticketFormData.append('tokenId', buildingTokenId);
    ticketFormData.append('escalate', ticket.escalate);
    ticketFormData.append('firstName', ticket.firstName);
    ticketFormData.append('lastName', ticket.lastName);
    ticketFormData.append('email', ticket.email);
    ticketFormData.append('phoneNumber.number', ticket.phoneNumber.number);
    ticketFormData.append('phoneNumber.countryCode', ticket.phoneNumber.countryCode);
    ticketFormData.append('apartmentNumber', ticket.apartmentNumber);
    ticketFormData.append('description', (ticket.problemDescription));
    ticketFormData.append('classificationId', (ticket.classification?.id));
    ticket.images.forEach((image) => ticketFormData.append('images', image, image.path));

    createCase(ticketFormData, {
      onSuccess: () => {
        setTicket();
        setIsOpen(false);
      },
    });
  };

  const cancel = () => {
    setIsOpen(false);
    setTicket();
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={cancel}
      className="modal-dialog--primary"
    >
      <div className="modal__header">
        <button
          type="button"
          aria-label="modal__close-btn"
          className="lnr lnr-cross modal__close-btn"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-modal modal__title">{t('ticket:sendNewNotification')}</h5>
      </div>
      <div className="modal__body">
        <CreateTicketForm ticket={ticket} updateTicket={setTicket} onCancel={cancel} onSubmit={submit} allowEscalation={allowEscalation} />
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCreatingCase}
      >
        <LoadingSpinner />
      </Backdrop>
    </Modal>
  );
};

CreateTicketModal.propTypes = {
  allowEscalation: PropTypes.bool.isRequired,
  buildingTokenId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default CreateTicketModal;
