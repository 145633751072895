import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

const RichTextEditor = ({
  initialValue, callback, height, toolbarItems, showMenubar,
}) => {
  const handleEditorChange = (value) => {
    callback(value);
  };

  return (
    <Editor
      value={initialValue}
      apiKey="jdy2a6zbqtmmbaaubvww3v3112rpz5krn7fm8lmo9iqff2x2"
      init={{
        selector: 'textarea',
        height,
        menubar: showMenubar,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste',
        ],
        toolbar: toolbarItems,
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

RichTextEditor.propTypes = {
  initialValue: PropTypes.string,
  callback: PropTypes.func,
  height: PropTypes.number,
  toolbarItems: PropTypes.string,
  showMenubar: PropTypes.bool,
};

RichTextEditor.defaultProps = {
  initialValue: '',
  callback: () => {},
  height: 500,
  toolbarItems: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
  showMenubar: false,
};

export default RichTextEditor;
