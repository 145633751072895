import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal, Button, ButtonToolbar, FormGroup,
} from 'reactstrap';
import {
  Form,
} from '@availity/form';
import {
  FormHelperText,
  Backdrop,
} from '@mui/material';
import API from '../../../shared/api/ApiService';
import useNotification from '../../../shared/hooks/UseNotification';
import RichTextEditor from '../../../shared/components/RichTextEditor';
import LoadingSpinner from '../../Layout/LoadingSpinner';

const AddNoteModal = ({
  isOpen, setIsOpen, ticketId,
}) => {
  const [note, setNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation(['ticket', 'common', 'errors']);
  const { sendNotification, sendDefaultError } = useNotification();

  const submit = () => {
    if (note && !isSubmitting) {
      setIsSubmitting(true);
      API.postAddNoteToTicket({ ticketId, note })
        .then((response) => {
          setIsSubmitting(false);
          if (response.status === 200) {
            setNote('');
            setIsOpen(false);
            sendNotification({
              message: t('successfullyCreatedNote'),
              header: t('common:success'),
              variant: 'success',
            });
          } else {
            sendDefaultError();
          }
        })
        .catch(() => {
          sendDefaultError();
          setIsSubmitting(false);
        });
    }
  };

  const cancel = () => {
    setIsOpen(false);
    setNote('');
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      className="modal-dialog--primary"
    >
      <div className="modal__header">
        <button
          type="button"
          aria-label="modal__close-btn"
          className="lnr lnr-cross modal__close-btn"
          onClick={cancel}
        />
        <h5 className="text-modal modal__title">{t('newNote')}</h5>
      </div>
      <div className="modal__body">
        <Form
          onSubmit={submit}
          initialValues={{
            note,
          }}
        >
          <FormGroup>
            <RichTextEditor
              id="note"
              name="note"
              toolbarItems="bold italic underline"
              initialValue={note}
              callback={(value) => setNote(value)}
              height={200}
            />
            {!note && (
              <FormHelperText error style={{ textAlign: 'center' }}>{t('errors:fieldIsRequired')}</FormHelperText>
            )}
          </FormGroup>

          <ButtonToolbar className="modal__footer">
            <Button
              outline
              color="secondary"
              type="button"
              size="lg"
              onClick={cancel}
            >
              <i><FontAwesomeIcon icon={['fad', 'times-circle']} /></i> {t('common:cancel')}
            </Button>
            <Button
              color="keypoint"
              type="submit"
              size="lg"
            >
              <i><FontAwesomeIcon icon={['fad', 'plus']} /></i> {t('common:add')}
            </Button>
          </ButtonToolbar>
        </Form>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <LoadingSpinner />
      </Backdrop>
    </Modal>
  );
};

AddNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
};

export default AddNoteModal;
