import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import Backdrop from '@mui/material/Backdrop';
import CreateTicketForm from './CreateTicketForm';
import API from '../../../shared/api/ApiService';
import useNotification from '../../../shared/hooks/UseNotification';
import LoadingSpinner from '../../Layout/LoadingSpinner';

const CreateTicketModal = ({
  isOpen, setIsOpen, buildingId, actorId, setTickets,
}) => {
  const [ticket, setTicket] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation(['ticket']);
  const { sendNotification, sendDefaultError } = useNotification();

  const refreshTickets = () => {
    setTickets([]);
    API.fetchGetBuildingTickets(buildingId)
      .then((response) => {
        response.json()
          .then((data) => {
            setTickets(data);
          });
      });
  };

  const submit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      API.postAssistanceTicket({
        buildingId,
        info: ticket.problemDescription,
        classificationTypeId: ticket.classificationTypeId,
        email: ticket.email,
        phoneNumber: ticket.phoneNumber,
        apartmentNumber: ticket.apartmentNumber,
        language: ticket.language,
        requestor: ticket.requester,
        escalate: ticket.escalate,
        notes: ticket.notes.filter((n) => n.note.trim()).map((n) => n.note),
      })
        .then((response) => {
          setIsSubmitting(false);
          if (response.status === 200) {
            setTicket();
            setIsOpen(false);
            sendNotification({
              message: t('successfullyCreatedTicket'),
              header: t('common:success'),
              variant: 'success',
            });
            refreshTickets();
          } else {
            sendDefaultError();
          }
        })
        .catch(() => {
          setIsSubmitting(false);
          sendDefaultError();
        });
    }
  };

  const cancel = () => {
    setIsOpen(false);
    setTicket();
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={cancel}
      className="modal-dialog--primary"
    >
      <div className="modal__header">
        <button
          type="button"
          aria-label="modal__close-btn"
          className="lnr lnr-cross modal__close-btn"
          onClick={cancel}
        />
        <h5 className="text-modal modal__title">{t('ticket:createNewTicket')}</h5>
      </div>
      <div className="modal__body">
        <CreateTicketForm ticket={ticket} actorId={actorId} updateTicket={setTicket} onCancel={cancel} onSubmit={submit} />
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <LoadingSpinner />
      </Backdrop>
    </Modal>
  );
};

CreateTicketModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  buildingId: PropTypes.number.isRequired,
  actorId: PropTypes.number.isRequired,
  setTickets: PropTypes.func.isRequired,
};

export default CreateTicketModal;
