class ApiService {
  constructor(baseUri) {
    this.api = baseUri;
    this.token = null;
  }

  /* Public Helpers */

  setToken = (token) => {
    this.token = token;
  };

  /* QR */
  fetchGetBuildingInfo = async (tokenId) => this.#noAuthGetResponse(`QR/building/${tokenId}`);

  fetchGetPublicTicketsForBuilding = async (tokenId) =>
    this.#noAuthGetResponse(`QR/building/${tokenId}/tickets`);

  /*  Assistance */

  postAssistanceTicket = async (data) => this.#defaultPostData('assistance/ticket/', data);

  postAddNoteToTicket = async (data) => this.#defaultPostData('assistance/ticket/note', data);

  fetchGetTicketClassificationsForActor = async (actorId) =>
    this.#defaultGetResponse(`assistance/ticketClasifications/${actorId}`);

  fetchGet247Actors = async () => this.#defaultGetResponse('assistance/247actors');

  fetchGetBuildingsOwnedActor = async (actorId) =>
    this.#defaultGetResponse(`assistance/actorOwnedBuildings/${actorId}`);

  fetchGetBuildingTickets = async (buildingId) =>
    this.#defaultGetResponse(`assistance/buildingTickets/${buildingId}`);

  fetchGetBuildingSuppliers = async (buildingId) =>
    this.#defaultGetResponse(`assistance/buildingSuppliers/${buildingId}`);

  fetchGetLanguages = async () => this.#defaultGetResponse('assistance/language/');

  /* Private Functions / Helpers */

  #defaultGetResponse = async (endpoint) =>
    fetch(`${this.api}/${endpoint}`, {
      method: 'GET',
      headers: this.#getBasicHeaders(),
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });

  #noAuthGetResponse = async (endpoint) =>
    fetch(`${this.api}/${endpoint}`, {
      method: 'GET',
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });

  #formPostData = async (endpoint, formData) =>
    fetch(`${this.api}/${endpoint}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });

  #defaultPostData = async (endpoint, body) =>
    fetch(`${this.api}/${endpoint}`, {
      method: 'POST',
      headers: this.#getPostHeaders(),
      body: JSON.stringify(body),
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });

  #getPostHeaders = () => ({
    Authorization: this.#getAuthorizationHeader(),
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  });

  #getBasicHeaders = () => ({
    Authorization: this.#getAuthorizationHeader(),
  });

  #getAuthorizationHeader = () => `Bearer ${this.token}`;
}

const { REACT_APP_KEYPOINT_API_URI } = process.env;
const API = new ApiService(REACT_APP_KEYPOINT_API_URI);

export default API;
