import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
} from 'reactstrap';

const PageNotFound = () => {
  const { t } = useTranslation(['errors']);

  return (
    <Container className="building-not-found">
      <div className="error-title">404</div>
      <div className="error-subtitle">{t('buildingWasNotFound')}</div>
    </Container>
  );
};

export default PageNotFound;
