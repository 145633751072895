/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTableButtonsProps } from '../prop-types/CreateTableProps';

function moveArrayObjectToNewIndex(array, initialIndex, finalIndex) {
  array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
}

const CreateTableData = ({
  jsonData,
  properties,
  disableGlobalFilterProperties,
  disableSortByProperties,
  buttons,
  t,
}) => {
  let columns = [];
  if (jsonData.length > 0) {
    const distinctKeys = new Set(jsonData.flatMap((obj) => Object.keys(obj)));
    columns = [...distinctKeys]
      .filter((key) => properties.includes(key))
      .map((key) => {
        const header = t(`common:${key}`);
        return {
          accessor: key,
          disableGlobalFilter: key === 'id' || disableGlobalFilterProperties?.includes(key),
          disableSortBy: key === 'id' || disableSortByProperties?.includes(key),
          Header: key === 'id' ? '' : header,
          originalHeader: key,
        };
      })
      .sort((a, b) => (properties.indexOf(a.originalHeader) < properties.indexOf(b.originalHeader) ? -1 : 1));

    if (buttons !== null && buttons !== undefined && buttons.length > 0) {
      buttons.forEach((button) => {
        const column = columns.find((col) => col.accessor === button.accessor);
        if (column !== null && column !== undefined) {
          column.Cell = button.content;
        }
      });
    }
  }
  const reactTableData = { tableHeaderData: columns, tableRowsData: jsonData };
  return reactTableData;
};

CreateTableData.propTypes = {
  buttons: CreateTableButtonsProps,
  disableGlobalFilterProperties: PropTypes.arrayOf(PropTypes.string),
  disableSortByProperties: PropTypes.arrayOf(PropTypes.string),
};

export default CreateTableData;
