import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEN from './en/common.json';
import commonNL from './nl/common.json';
import commonFR from './fr/common.json';
import ticketEN from './en/ticket.json';
import ticketNL from './nl/ticket.json';
import ticketFR from './fr/ticket.json';
import adminEN from './en/admin.json';
import adminNL from './nl/admin.json';
import adminFR from './fr/admin.json';
import errorsEN from './en/errors.json';
import errorsNL from './nl/errors.json';
import errorsFR from './fr/errors.json';
import buildingEN from './en/building.json';
import buildingNL from './nl/building.json';
import buildingFR from './fr/building.json';

const resources = {
  en: {
    common: commonEN,
    ticket: ticketEN,
    admin: adminEN,
    errors: errorsEN,
    building: buildingEN,
  },
  nl: {
    common: commonNL,
    ticket: ticketNL,
    admin: adminNL,
    errors: errorsNL,
    building: buildingNL,
  },
  fr: {
    common: commonFR,
    ticket: ticketFR,
    admin: adminFR,
    errors: errorsFR,
    building: buildingFR,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
