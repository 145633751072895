import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Nullable, PhoneNumber } from '../../../shared/types';
import { formatPhoneNumber } from '../../../shared/utils/helpers';
import PhoneCallButton from './PhoneCallButton';

type Props = {
  name: string;
  phoneNumber: Nullable<PhoneNumber>;
  emailAddress: Nullable<string>;
};

const BuildingTeamInfo: React.FC<Props> = ({ name, phoneNumber, emailAddress }) => {
  const { t } = useTranslation('common');

  return (
    <Card className="mb-3">
      <CardHeader style={{ textAlign: 'center' }} tag="h2">
        {name}
        {phoneNumber && <PhoneCallButton phoneNumber={phoneNumber} />}
      </CardHeader>
      <CardBody>
        {emailAddress && (
          <Row>
            <Col xs={4}>{t('email')}:</Col>
            <Col xs={8}>{emailAddress}</Col>
          </Row>
        )}
        {phoneNumber && (
          <Row>
            <Col xs={4}>{t('phone')}:</Col>
            <Col xs={8}>{formatPhoneNumber(phoneNumber)}</Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default BuildingTeamInfo;
