import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputLabel, Stack } from '@mui/material';
import RichTextEditor from '../RichTextEditor';
import { makeId } from '../../utils/helpers';

const NotetEditor = ({ note, updateNote, removeNote }) => {
  const { t } = useTranslation(['ticket']);

  return (
    <Container key={note.id} className="note-editor">
      <RichTextEditor
        toolbarItems="bold italic underline"
        initialValue={note.note}
        callback={(value) => updateNote(note.id, value)}
        height={150}
      />
      <Button
        type="button"
        className="mb-0 note-editor-remove-btn"
        color="danger"
        size="sm"
        onClick={removeNote}
      >
        <i><FontAwesomeIcon icon={['fad', 'trash']} /></i> {t('removeNote')}
      </Button>
    </Container>
  );
};

NotetEditor.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
  updateNote: PropTypes.func.isRequired,
  removeNote: PropTypes.func.isRequired,
};

const NotesInput = ({ notes, setNotes }) => {
  const { t } = useTranslation(['ticket']);

  const updateNote = (id, value) => {
    const items = [...notes];
    const i = notes.findIndex((n) => n.id === id);
    items[i].note = value;

    setNotes(items);
  };

  return (
    <>
      <div className="notes-input-header">
        <InputLabel style={{ textAlign: 'left' }} className="float-left">{t('notes')}</InputLabel>
        <Button
          type="button"
          className="mb-0 float-right"
          color="keypoint"
          size="sm"
          onClick={() => setNotes([...notes, { id: makeId(7), note: '' }])}
        >
          <i><FontAwesomeIcon icon={['fad', 'plus']} /></i> {t('newNote')}
        </Button>
      </div>
      <Stack spacing={2} style={{ alignContent: 'left' }}>
        {notes.map((note) => (
          <NotetEditor
            key={note.id}
            note={note}
            updateNote={updateNote}
            removeNote={() => setNotes([...notes.filter((n) => n.id !== note.id)])}
          />
        )).reverse()}
      </Stack>
    </>
  );
};

NotesInput.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
  })),
  setNotes: PropTypes.func.isRequired,
};

NotesInput.defaultProps = {
  notes: [],
};

export default NotesInput;
