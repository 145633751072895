import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'oidc-react';
import customOidcConfig from './shared/auth/oidc-config';
import App from './containers/App/App';
import i18n from './translations/i18next';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...customOidcConfig(i18n)}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
