import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import qrApi from '../../shared/api/qr/qr';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useCreateCase = () => {
  const { t } = useTranslation(['common', 'ticket']);
  const { sendNotification, sendDefaultError } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: qrApi.createCase,
    onError: sendDefaultError,
    onSuccess: () => {
      queryClient.invalidateQueries([queryIds.qr.GET_BUILDING_INFO]);

      sendNotification({
        header: t('common:success'),
        message: t('ticket:successfullySendNotification'),
        variant: 'success',
      });
    },
  });

  return {
    createCase: mutate,
    isCreatingCase: isLoading,
  };
};

export default useCreateCase;
