import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Stack, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MultiFileUpload = ({ files, setFiles }) => {
  const { t } = useTranslation(['common']);
  const [filesAdded, setFilesAdded] = useState(false);
  const {
    acceptedFiles, fileRejections, getRootProps, getInputProps,
  } = useDropzone({
    accept: 'image/*',
    maxFiles: 10,
    maxSize: 15000000,
    onDrop: () => { setFilesAdded(false); },
  });

  useEffect(() => {
    if (acceptedFiles.length && !filesAdded) {
      const unAddedFiles = acceptedFiles.filter((file) => !files.some((f) => f.path === file.path));
      setFiles([...files, ...unAddedFiles]);
      setFilesAdded(true);
    }
  }, [acceptedFiles, files, filesAdded, setFiles]);

  const removeFile = (fileToRemove) => {
    setFiles(files.filter((file) => file.path !== fileToRemove.path));
  };

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{t('uploadImages')}</p>
      </div>
      <Stack spacing={1} className="dropzone-files">
        {files.map((file) => (<Chip key={file.path} label={file.path} onDelete={() => removeFile(file)} />))}
      </Stack>
      {fileRejections.length > 0 && (
      <Stack spacing={1} className="dropzone-rejected-files">
        <h4>Errors</h4>
        {fileRejections.map((rejectedFile) => (
          <Chip key={rejectedFile.file.path} className="dropzone-rejected-file" label={`${rejectedFile.file.path} - ${rejectedFile.errors.map((error) => error.message).join(', ')} `} />
        ))}
      </Stack>
      )}
    </>
  );
};

MultiFileUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
  })),
  setFiles: PropTypes.func.isRequired,
};

MultiFileUpload.defaultProps = {
  files: [],
};

export default MultiFileUpload;
